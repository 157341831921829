<template>
  <client-only>
    Error
  </client-only>
</template>

<script>
export default {

  asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    const slug = route.fullPath.split('/').pop().replace(/[#?].*$/, '')
    const content = store.getters.contentBySlug[slug]
    if (!content && slug !== 'error') {
      error({ statusCode: 404, message: 'Brand platform content not found.' })
    }
    return {
      slug,
      content
    }
  }
}
</script>
